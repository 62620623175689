.pageSelector {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageSelector p {
  margin: 0;
}

.ais-Pagination-list {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ais-Pagination-item {
  margin: 0em 1em;
}

.ais-Pagination-item > a {
  color: var(--primColor);
}
