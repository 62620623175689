.productView {
  position: relative;
}

.productView .closeSlider {
  min-width: max-content;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}

.productView .image-gallery-thumbnails-container {
  text-align: left;
}

.productView .image-gallery-icon {
  filter: none;
  background: #00000036;
  padding: 10px;
}

.productView .image-gallery-icon:hover {
  color: var(--primColor);
}

.productView .image-gallery-thumbnail {
  border: 2px solid transparent;
}

.productView .image-gallery-thumbnail.active,
.productView .image-gallery-thumbnail:hover,
.productView .image-gallery-thumbnail:focus {
  outline: none;
  border: 2px solid var(--primColor);
  border-radius: 5px;
}

.productView h3.pvHead {
  font-size: 18px;
  color: var(--secColor);
  margin: 20px 0;
  text-align: center;
  font-weight: 600;
}

.productView .pvActBtn .pabChild {
  text-align: center;
}

.productView .pvActBtn {
  background: #f8f9fb;
  padding: 15px 10px;
}

.productView .pvBidBox {
  width: 350px;
  margin: 0 auto;
}

.productView .pvActBtn .pabChild label {
  font-size: 14px;
  color: var(--secColor);
}

.productView .pvActBtn .pabChild h4 {
  font-size: 18px;
  color: var(--primColor);
  font-weight: 700;
}

.productView .pvActBtn .pabChild.pvCurrentBid h4 {
  color: #353535;
}

.productView .pvBidHistBtn {
  color: var(--primColor);
  text-transform: initial;
  margin: 10px auto;
  display: block;
}

.productView .pvBidBox .customInput {
  margin: 0;
  width: 58%;
}

.productView .pvBidBox .primButton {
  width: 40%;
}

.productView .pvProductInfo p {
  font-size: 18px;
  color: #000000;
  margin: 0;
  line-height: 20px;
  font-weight: 600;
}

.productView .pvProductDesc label {
  font-size: 14px;
  color: #595959;
  font-weight: 700;
  margin: 0;
  padding-right: 10px;
}

.productView .pvProductDesc h6 {
  font-size: 14px;
  color: #595959;
  font-weight: 400;
  margin: 0;
  display: inline;
}

.productView .pvProductDesc .pvDescChild {
  margin-bottom: 10px;
}

.productView .cartHead {
  font-size: 24px;
  color: #353535;
  font-weight: 700;
  margin: 30px 0 10px 0;
  text-transform: uppercase;
}

.productView .MuiPaper-root.MuiAccordion-root {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.productView .MuiButtonBase-root.MuiAccordionSummary-root {
  margin-bottom: 0;
}

.productView .MuiAccordionDetails-root {
  font-size: 15px;
}

.productView .accTitle {
  margin-bottom: 0;
  font-weight: 600;
}

.productView .image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
}

.productView .image-gallery-content .image-gallery-slide .image-gallery-image {
  height: 500px;
}

.productView .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 40px;
  width: 40px;
}

.starRating {
  color: var(--starColor);
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 2px;
}
.winning {
  padding: 10px;
  background: #1ecb6e;
  color: #fff;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  width: 100%;
}
.outBid {
  padding: 10px;
  background: var(--primColor);
  color: #fff;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  width: 100%;
}

@media (max-width: 500px) {
  .productView .pvBidBox {
    width: 100%;
  }
  .productView .cartHead {
    font-size: 18px;
    color: #353535;
    font-weight: 700;
    margin: 20px 0 10px 0;
    text-transform: uppercase;
  }
}

@media (max-width: 420px) {
  .productView .pvBidBox .biddingCnt {
    flex-wrap: wrap;
  }
  .productView .pvBidBox .biddingCnt .customInput {
    width: 100%;
    margin-bottom: 15px;
  }
  .productView .pvBidBox .biddingCnt .primButton,
  .productView .pvBidBox .biddingCnt .secButton {
    width: 100%;
  }
  .similarItem .pclImg img {
    height: 90px;
  }
}
