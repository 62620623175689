.filterPanel {
  position: sticky;
  top: 15px;
}

/* GRID */
.productCardGrid {
  width: 280px;
  text-align: left;
  background: #fff;
  padding: 10px;
  margin-bottom: 10px;
  height: auto;
}

.productCardGrid .pcgImg {
  width: 100%;
  height: 180px;
  position: relative;
}

.productCardGrid .pcgImg .favoriteCheck {
  position: absolute;
  top: 0;
  left: 0;
}

.productCardGrid .primButton.small button,
.productCardGrid .secButton.small button {
  font-size: 13px;
  min-width: max-content;
  width: 100%;
}

.statusMessage > span {
  font-size: 1rem;
  line-height: 1.2;
  color: var(--primColor) !important;
  vertical-align: middle;
}
.statusMessage > h6 {
  display: inline-block;
  margin-left: 3px;
}
.productCardGrid .outBid,
.productCardList .listBidStatus .outBid {
  padding: 10px;
  background: var(--primColor);
  color: #fff;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.productCardGrid .winning,
.productCardList .listBidStatus .winning {
  padding: 10px;
  background: #1ecb6e;
  color: #fff;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.productCardGrid .outBid,
.productCardList .listBidStatus .outBid {
  padding: 10px;
  background: var(--primColor);
  color: #fff;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.productCardGrid .winning,
.productCardList .listBidStatus .winning {
  padding: 10px;
  background: #1ecb6e;
  color: #fff;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.productCardGrid .pcgImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.productCardGrid .gridProdTitle {
  font-size: 18px;
  color: var(--primColor);
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 4.5ex;
}

.productCardGrid .gridLotInfo {
  margin-bottom: 5px;
}

.productCardGrid .gridLotInfo h5 {
  color: var(--secColor);
  font-size: 14px;
  margin: 0;
}

.productCardGrid .gridLotInfo .gridDivider {
  padding: 0 10px;
}

.productCardGrid .gridLotDetails p {
  font-size: 13px;
  color: var(--secColor);
  font-weight: 600;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 5ex;
}

.productCardGrid .gridTimer h6,
.productCardGrid .gridBidInfo h6 {
  margin: 0;
  font-size: 14px;
  color: var(--secColor);
}

.productCardGrid .gridTimer p,
.productCardGrid .gridBidInfo p {
  margin: 0;
  font-size: 14px;
  color: var(--primColor);
  font-weight: 600;
}

.productCardGrid .gridBidInfo p span.gridDivider {
  padding: 0 15px;
}

.productCardGrid .gridBidInfo {
  margin-top: 5px;
  height: 4.5ex;
}

.productCardGrid .gridTimer p span {
  padding-right: 5px;
}

.productCardGrid .gridBidBox {
  margin-top: 15px;
}

.productCardGrid .gridBidBox .MuiFormHelperText-root.Mui-error {
  width: 100%;
  margin-left: 0;
}

.productCardGrid .gridBidBox .customInput {
  margin: 0;
  margin-right: 5px;
  width: 49%;
}

.productCardGrid .gridBidBox .customInput input::placeholder {
  font-size: 12px;
}

.productCardGrid .gridBidBox .primButton {
  /* width: 49%; */
  width: auto;
}

.productCardGrid .moreInfo button {
  color: var(--primColor);
  text-transform: initial;
}

.productCardGrid .returnItemAct .MuiFormGroup-root .MuiFormControlLabel-root {
  width: 100%;
  margin: 0;
}

.productCardGrid
  .returnItemAct
  .MuiFormGroup-root
  .MuiFormControlLabel-root
  .MuiButtonBase-root
  ~ .MuiFormControlLabel-label {
  border: 1px solid var(--primColor);
  background: #fff;
  text-transform: uppercase;
  color: var(--primColor);
  border-radius: 3px;
}

.productCardGrid
  .returnItemAct
  .MuiFormGroup-root
  .MuiFormControlLabel-root
  .MuiButtonBase-root.Mui-checked
  ~ .MuiFormControlLabel-label {
  background: var(--primColor);
  color: #fff;
}

.productCardGrid .returnItemAct .MuiFormGroup-root .MuiCheckbox-root {
  display: none;
}

.productCardGrid
  .returnItemAct
  .MuiFormGroup-root
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  font-weight: 500;
  font-size: 15px;
  width: 100%;
}

.productCardGrid
  .returnItemAct
  .MuiFormGroup-root
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label
  .MuiListItem-button {
  justify-content: center;
}

/* GRID END */

/* SPECIAL EVENT */

.specialEvent {
  background: #fff;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.specialEvent .specialEventLt {
  width: 70%;
}

.specialEvent .seImg {
  width: 100%;
  max-width: 250px;
  position: relative;
  margin-right: 20px;
}

.specialEvent .seImg img {
  width: 100%;
  height: 130px;
  object-fit: contain;
}

.specialEvent .seContent {
  margin-right: 15px;
  text-align: left;
}

.specialEvent .seContent h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--primColor);
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.specialEvent .seContent p {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
  margin-bottom: 5px;
}

.specialEventDrawer .calItems {
  padding-bottom: 20px;
}

.specialEventDrawer .calItems h3 {
  font-size: 14px;
  color: var(--secColor);
  font-weight: 700;
  padding: 25px 16px 5px;
  text-align: left;
  text-transform: uppercase;
}

.specialEventDrawer .calItems .ciIcon {
  padding-right: 15px;
}

.specialEventDrawer .MuiPaper-root.MuiDrawer-paper {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

p.seAddress {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.specialEvent .seActBtn {
  width: 30%;
  max-width: 250px;
}

.specialEvent .seActBtn .atcBtn {
  padding-left: 0;
}

.specialEvent .seActBtn .seLinks button.MuiButton-root,
.specialEvent .MuiFab-primary {
  color: var(--primColor);
  text-transform: initial;
  font-size: 14px;
  width: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.specialEvent .seActBtn .seLinks button.MuiButton-root:first-child {
  margin-top: 5px;
  padding-left: 0;
}

.specialEvent .seActBtn .seLinks button.MuiButton-root i,
.specialEvent .MuiFab-primary i {
  padding-right: 10px;
}

.specialEvent .MuiFab-primary {
  background-color: #fff;
  box-shadow: none;
  width: max-content;
  margin: auto;
  border-radius: 3px;
  height: auto;
  /* flex-shrink: 0; */
  min-width: max-content;
}

.specialEvent .MuiFab-primary:hover {
  background-color: #fff;
  box-shadow: none;
}

.specialEvent .MuiSpeedDial-directionLeft {
  flex-direction: row;
  padding: 6px 8px;
  height: 36px;
  position: relative;
}

.specialEvent .MuiSpeedDial-actions {
  position: absolute;
  /* padding: 6px 8px; */
  /* left: 50px;
    transform: translateX(-100%); */
}

/* SPECIAL EVENT END */

/* LIST */

.productCardList {
  background: #fff;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.productCardList .psListLt {
  width: 70%;
}

.productCardList .pclImg {
  width: 100%;
  max-width: 250px;
  position: relative;
  margin-right: 20px;
}

.productCardList .pclImg img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 3px;
}

.productCardList .pclImg .favoriteCheck {
  position: absolute;
  top: 0;
  left: 0;
}

.productCardList .listContent {
  width: max-content;
  text-align: left;
  margin-right: 20px;
}

.productCardList .listActBtn {
  width: 30%;
  max-width: 280px;
}

.productCardList .listActBtn .customInput {
  width: 40%;
}

.productCardList .listActBtn .primButton,
.productCardList .listActBtn .secButton {
  width: 58%;
}

.productCardList .listContent .listProdTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--primColor);
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.listProdTitle {
  cursor: pointer;
}

.productCardList .listContent .listLotInfo {
  margin: 5px 0;
}

.productCardList .listContent .listLotInfo h5 {
  color: var(--secColor);
  font-size: 14px;
  margin: 0;
  line-height: 25px;
}

.productCardList .listContent .listLotInfo .gridDivider {
  padding: 0 10px;
}

.productCardList .listActBox {
  width: 30%;
}

.productCardList p {
  font-size: 14px;
  color: var(--secColor);
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.productCardList .listTimer h6,
.productCardList .listBidInfo h6 {
  margin: 0;
  font-size: 14px;
  color: var(--secColor);
  padding-right: 10px;
}

.productCardList .listTimer p,
.productCardList .listBidInfo p {
  margin: 0;
  font-size: 14px;
  color: var(--primColor);
  font-weight: 600;
}

.productCardList .listBidInfo p span.listDivider {
  padding: 0 15px;
}

.productCardList .listBidInfo {
  margin-top: 5px;
}

.productCardList .listTimer p span {
  padding-right: 5px;
}

.productCardList .listBidBox {
  margin: 15px 0;
}

.productCardList .listBidBox .customInput {
  margin: 0;
}

.productCardList button.bidDetails {
  text-transform: initial;
  color: var(--primColor);
  margin-top: 5px;
  width: 100%;
}

/* LIST END */

/* CART ITEM */

.cartItem {
  background: #fff;
  padding: 10px;
  margin-bottom: 10px;
}

.cartItem .itemInfo {
  width: 75%;
}

.cartItem .pclImg {
  width: 100%;
  max-width: 200px;
  position: relative;
  margin-right: 20px;
}

.cartItem .pclImg img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 3px;
}

.cartItem .listContent {
  width: max-content;
  text-align: left;
  margin-right: 20px;
}

.cartItem .listActBtn {
  width: 25%;
  max-width: max-content;
}

.cartItem .listContent .listProdTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--primColor);
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.cartItem .listContent .listLotInfo {
  margin: 5px 0;
}

.cartItem .listContent .listLotInfo h5 {
  color: var(--secColor);
  font-size: 14px;
  margin: 0;
  line-height: 25px;
}

.cartItem .listContent .listLotInfo .gridDivider {
  padding: 0 10px;
}

.cartItem .listActBox {
  width: 30%;
}

.cartItem .listBidInfo p {
  font-size: 16px;
  color: var(--secColor);
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.cartItem .listBidInfo h6 {
  margin: 0;
  font-size: 14px;
  color: var(--secColor);
  padding-right: 10px;
}

.cartItem .listBidInfo p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}

.cartItem .listBidInfo p span:first-child {
  color: var(--primColor);
}

.cartItem .listBidInfo p span.listDivider {
  padding: 0 15px;
}

.cartItem .listBidInfo {
  margin-top: 5px;
}

.cartItem .listTimer p span {
  padding-right: 5px;
}

.cartItem .listBidBox .customInput {
  margin: 15px 0;
}

.cartItem button.removeCart {
  text-transform: initial;
  color: var(--primColor);
  margin-top: 5px;
  width: 100%;
}

/* CART ITEM END */

/* SIMILAR ITEMS */
.similarItem {
  background: #fff;
  padding: 10px;
  /* margin-bottom: 10px; */
}

.similarItem .pclImg {
  width: 25%;
  margin-right: 30px;
}

.similarItem .pclImg img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 3px;
}

.similarItem .listContent {
  text-align: left;
  width: 70%;
}

.similarItem .listContent .listProdTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--primColor);
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.similarItem .listContent .listLotInfo {
  margin: 5px 0;
}

.similarItem .listContent .listLotInfo h5 {
  color: var(--secColor);
  font-size: 14px;
  margin: 0;
  line-height: 25px;
}

.similarItem .listContent .listLotInfo .gridDivider {
  padding: 0 10px;
}

/* SIMILAR ITEMS END */

.actionAfterWin {
  margin: 15px 0 5px 0;
}

.actionAfterWin .primButton,
.actionAfterWin .secButton {
  width: 100% !important;
}

/* Skeletons */

.gridViewSkeleton {
  width: 100%;
  height: 460px;
  max-width: 350px;
}

.listViewSkeleton {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1.5fr 3fr 1.5fr;
  margin: 15px 0;
  background: #f7f7f7;
}

.specialEventSkeleton {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1.3fr 3fr 1.7fr;
  margin: 15px 0;
  background: #f7f7f7;
}

.specialEventSkeleton .skeleton.gridImage {
  height: 120px;
}

/* .bidHistoryModal .custom-modal .modal-dialog.modal-lg {
    max-width: 500px;
} */

/* .bidHistoryModal table thead tr th:first-child,
.bidHistoryModal table tbody tr td:first-child {
    width: 55%;
} */

.bidHistoryModal table thead th,
.bidHistoryModal table tbody td {
  text-align: left;
  white-space: nowrap;
}

/* @media (min-width: 1400px) {
    .productCardGrid {
        width: 32%;
    }
} */

/* @media (min-width: 1900px) {
    .productCardGrid {
        width: 23%;
    }
} */

@media (max-width: 768px) {
  .cartItem {
    flex-wrap: wrap;
    width: 100%;
  }
  .cartItem .itemInfo {
    width: 100% !important;
    flex-wrap: wrap;
  }

  .cartItem .pclImg {
    max-width: none !important;
    margin-right: 0;
  }

  .cartItem .pclImg img {
    height: 200px;
  }
  .cartItem .listContent {
    width: 100%;
    margin-right: 0;
  }
  .cartItem .listActBtn {
    width: 100% !important;
  }
  .cartItem .listBidInfo p {
    justify-content: flex-start !important;
  }
  .cartItem button.removeCart {
    width: auto;
    float: left;
  }
  .cartItem .listContent .listLotInfo h5 {
    width: 100%;
  }
  .cartItem .listContent .listLotInfo {
    flex-wrap: wrap;
  }

  .cart .cartLt .cartInnerCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
  }
  .cartItem .listActBtn {
    flex-wrap: wrap;
  }
}

@media (max-width: 500px) {
  .specialEvent .seContent h2 {
    font-size: 16px;
    line-height: 20px;
  }
  .specialEvent .seContent p {
    font-size: 13px;
    margin-bottom: 2px;
  }

  .cartItem .pclImg img {
    height: 150px;
  }
  .cartItem .itemInfo {
    width: 100%;
  }
  .productCardGrid .gridProdTitle {
    font-size: 16px;
  }
  .productCardGrid .gridTimer h6,
  .productCardGrid .gridBidInfo h6 {
    font-size: 13px;
  }
  .search .searchResults .productCardGrid,
  .dashboard .productCardGrid {
    width: 100%;
  }

  .productCardGrid .gridBidBox .customInput,
  .productCardGrid .gridBidBox .customInput .MuiFormControl-root.MuiTextField-root {
    height: 45px;
  }
  .productCardGrid .gridBidBox .customInput input {
    height: 100%;
  }
  .productCardGrid .gridBidBox .primButton,
  .productCardGrid .gridBidBox .secButton {
    width: 49%;
  }
  .similarItem .listContent .listProdTitle {
    font-size: 15px;
  }
  .similarItem .pclImg {
    width: 30%;
    margin-right: 10px;
  }

  .cartItem {
    width: 100%;
  }

  .cartItem .listActBtn {
    display: flex;
    max-width: none;
    justify-content: space-between !important;
  }

  .cartItem .listActBtn {
    display: flex;
    max-width: none;
    justify-content: space-between !important;
  }

  .cartItem button.removeCart {
    width: max-content;
  }
}

@media (max-width: 450px) {
  .specialEvent .seContent h2 {
    font-size: 14px;
  }
  .specialEvent .seContent p {
    font-size: 12px;
  }
  .specialEvent .seContent {
    margin-bottom: 10px;
  }
  .cartItem .listBidInfo {
    width: 100%;
  }
}
