footer .footerCnt {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #e8e8e8;
}

footer .footLinks {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
}

footer .footLinks .flHead {
  padding-left: 16px;
  color: #505d68;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

footer .footLinks a {
  text-decoration: none;
}

footer .footLinks a:hover {
  color: #505d68;
}

footer .footLinks li,
footer .footLinks .MuiButtonBase-root.MuiListItem-root {
  font-size: 15px;
  color: #a1aeb7;
}

footer .footLinks .noLink {
  padding: 8px 16px;
}

footer .footSubscribe .subsInp {
  width: 75%;
}

footer .footSubscribe .customInput {
  margin-bottom: 0;
}

footer .footSubscribe .primButton {
  height: 58px;
  margin-left: 10px;
  min-width: max-content;
}

footer .footSubscribe .primButton button {
  height: 100%;
}

footer .footSubscribe button:hover {
  color: #fff;
  background: var(--primColor);
  opacity: 0.9;
}

footer .footSubscribe {
  margin: 16px 0 0 16px;
}
footer .footSubscribe input {
  border: 1px solid #ccc;
  -webkit-transition: 200ms border-color ease-in-out;
  -o-transition: 200ms border-color ease-in-out;
  transition: 200ms border-color ease-in-out;
}
footer .footSubscribe input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: var(--primColor);
}

footer .footSubscribe button {
  font-size: 15px;
}

footer .footSubscribe button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

footer .footBottom {
  border-top: 1px solid #c2d1d9;
  padding: 10px 0;
}

footer .footBottom ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

footer .footBottom ul li:not(:last-child) {
  margin-right: 60px;
}

footer .footBottom ul li a,
footer .footBottom ul li p {
  margin: 0;
  font-size: 14px;
  color: #505d68;
}

@media (max-width: 800px) {
  footer .footerCnt {
    flex-wrap: wrap;
  }

  footer .footerCnt ul:last-child {
    width: 100%;
    margin-top: 15px;
  }
  footer .footSubscribe {
    margin: 5px 0 0 16px;
  }
}

@media (max-width: 767px) {
  footer .footLinks li,
  footer .footLinks .MuiButtonBase-root.MuiListItem-root {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media (max-width: 600px) {
  footer .footBottom {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  footer .footBottom ul:first-child {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
}

@media (max-width: 500px) {
  footer .footLinks li,
  footer .footLinks .MuiButtonBase-root.MuiListItem-root {
    padding-top: 3px;
    padding-bottom: 3px;
    word-break: break-word;
  }
  footer .footSubscribe {
    margin-right: 16px;
  }
}

@media (max-width: 450px) {
  footer .footLinks {
    width: 48%;
    margin-bottom: 10px;
  }
  footer .footerCnt {
    padding-bottom: 0;
  }
}

@media (max-width: 400px) {
  footer .footBottom ul li:not(:last-child) {
    margin-right: 30px;
  }
  footer .footBottom ul li a,
  footer .footBottom ul li p {
    font-size: 12px;
  }
}

@media (max-width: 350px) {
  footer .footLinks {
    width: 50%;
  }
  footer .footLinks li,
  footer .footLinks .MuiButtonBase-root.MuiListItem-root {
    font-size: 13px;
  }
  footer .footLinks .flHead,
  footer .footLinks li,
  footer .footLinks .MuiButtonBase-root.MuiListItem-root,
  footer .footLinks .noLink {
    padding-left: 0px;
  }
  footer .footSubscribe {
    margin-left: 0;
    margin-right: 0;
  }
}
