.emailContainer {
  background: #fff;
  padding: 45px 25px;
  max-width: 700px;
  margin: 45px auto;
}

.emailContainer h3 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
}

.emailContainer.emailError h3 {
  color: #e44f32;
}

.emailContainer.emailSuccessful h3 {
  color: #17bf4b;
}

.emailContainer .primButton {
  width: max-content;
  margin: 20px auto;
}

.emailContainer object {
  width: 450px;
  margin: 10px 0;
}
.loginLt {
  padding-left: 100px;
  padding-right: 100px;
}
.loginLt h1 {
  font-size: 34px;
  color: var(--secColor);
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
}
.loginLt .MuiListItem-button {
  padding: 0;
}

.login .loginRt h1 {
  font-size: 34px;
  color: var(--secColor);
  margin-bottom: 10px;
  text-align: center;
  font-weight: 700;
}

.loginLt .fpLink {
  font-size: 14px;
  color: var(--primColor);
  font-weight: 600;
  text-align: right;
  display: block;
  float: right;
  margin-top: 10px;
}

.socialButtons {
  margin-bottom: 30px;
}

.socialButtons button {
  height: 45px;
}

.socialButtons .MuiButton-label {
  font-size: 13px;
  text-transform: initial;
}

.socialButtons .MuiButton-label object {
  margin-right: 10px;
}

.forgotPassword .loginLt {
  margin-right: 0;
}

.forgotPassword .loginLt h1 {
  margin-bottom: 10px;
}

.forgotPassword .loginLt > p {
  font-size: 15px;
  margin-bottom: 45px;
}

@media (max-width: 1024px) {
  .login .loginCnt {
    max-width: none;
  }
  .socialButtons button:first-child {
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .login {
    padding: 35px 0;
  }
  .loginCnt .loginBox {
    flex-wrap: wrap;
  }
  .loginLt {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .login .loginRt .regIcon {
    margin: 40px auto;
  }
}

@media (max-width: 500px) {
  .socialButtons {
    flex-wrap: wrap;
  }
  .socialButtons button {
    width: 100%;
  }

  .socialButtons button:first-child {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .loginLt h1,
  .login .loginRt h1 {
    font-size: 28px;
  }
}

@media (max-width: 450px) {
  .loginLt .loginDivider::before,
  .loginLt .loginDivider::after {
    width: 15%;
  }
}

@media (max-width: 400px) {
  .loginLt,
  .login .loginRt {
    padding: 25px 0;
  }
  .login {
    background: #fff;
  }
}

@media (max-width: 380px) {
  .loginLt .loginDivider::before,
  .loginLt .loginDivider::after {
    width: 0%;
  }
}
