.login {
  background: var(--backgroundColor);
  padding: 75px 0;
}

.login .loginLt,
.login .loginRt {
  padding: 25px;
  background: #fff;
  max-width: 450px;
  width: 100%;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  /* max-height: 430px; */
}

.login .loginLt h1 {
  font-size: 34px;
  color: var(--secColor);
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
}
.login .loginLt .MuiListItem-button {
  padding: 0;
}

.login .loginRt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.login .loginRt h1 {
  font-size: 34px;
  color: var(--secColor);
  margin-bottom: 10px;
  text-align: center;
  font-weight: 700;
}

.login .loginLt .fpLink {
  font-size: 13px;
  color: var(--primColor);
  font-weight: 600;
  text-align: right;
  display: block;
  margin-top: 10px;
}

.login .loginLt {
  margin-right: 25px;
}

.login .loginLt .loginDivider {
  text-transform: uppercase;
  margin: 35px 0;
  position: relative;
  font-size: 15px;
}

.login .loginLt .loginDivider::before {
  position: absolute;
  content: '';
  height: 2px;
  width: 40%;
  top: 50%;
  left: 0;
  background: #ebebeb;
}

.login .loginLt .loginDivider::after {
  position: absolute;
  content: '';
  height: 2px;
  width: 40%;
  top: 50%;
  right: 0;
  background: #ebebeb;
}

.login .loginLt .socialButtons {
  margin-bottom: 30px;
}

.login .loginLt .socialButtons button {
  height: 45px;
}

.login .loginLt .socialButtons .MuiButton-label {
  font-size: 13px;
  text-transform: initial;
}

.login .loginLt .socialButtons .MuiButton-label object {
  margin-right: 10px;
}

.login .loginRt .regIcon {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background: #f1f1f1;
  border: 1px solid #dbdbdb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0px auto;
}

.login .loginRt .regIcon object {
  width: 40px;
}

.login.forgotPassword .loginLt {
  margin-right: 0;
}

.login.forgotPassword .loginLt h1 {
  margin-bottom: 10px;
}

.login.forgotPassword .loginLt > p {
  font-size: 15px;
  margin-bottom: 45px;
}

@media (max-width: 1024px) {
  .login .loginCnt {
    max-width: none;
  }
  .login .loginLt .socialButtons button:first-child {
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .login {
    padding: 35px 0;
  }
  .login .loginCnt .loginBox {
    flex-wrap: wrap;
  }
  .login .loginLt {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .login .loginRt .regIcon {
    margin: 40px auto;
  }
}

@media (max-width: 500px) {
  .login .loginLt .socialButtons {
    flex-wrap: wrap;
  }
  .login .loginLt .socialButtons button {
    width: 100%;
  }

  .login .loginLt .socialButtons button:first-child {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .login .loginLt h1,
  .login .loginRt h1 {
    font-size: 28px;
  }
}

@media (max-width: 450px) {
  .login .loginLt .loginDivider::before,
  .login .loginLt .loginDivider::after {
    width: 15%;
  }
}

@media (max-width: 400px) {
  .login .loginLt,
  .login .loginRt {
    padding: 25px 0;
  }
  .login {
    background: #fff;
  }
}

@media (max-width: 380px) {
  .login .loginLt .loginDivider::before,
  .login .loginLt .loginDivider::after {
    width: 0%;
  }
}
