.cart {
  background: var(--backgroundColor);
  padding-top: 30px;
  padding-bottom: 30px;
}

.cart .cartLt .cartHead {
  margin-bottom: 15px;
}
.cart .cartProducts {
  margin-bottom: 30px;
}

.cart .cartLt .cartHead h1 {
  font-size: 25px;
  color: #353535;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}

.cart .cartLt .cartHead span {
  font-size: 14px;
  color: #999999;
  margin-left: 10px;
  font-weight: 700;
}

.cart .cartLt {
  width: 63%;
}

.cart .cartRt {
  width: 35%;
  text-align: left;
}

.cart .cartRt .cartSummary {
  background: #ffffff;
  padding: 15px;
  position: sticky;
  top: 15px;
}

.cart .cartRt .cartSummary .csInfo {
  margin-bottom: 5px;
}

.cart .cartRt .cartSummary .csInfo label {
  font-size: 15px;
  margin: 0;
}

.cart .cartRt .cartSummary .csInfo h5 {
  font-size: 16px;
}

.cart .cartRt .cartSummary h3 {
  font-size: 16px;
  color: #595959;
  font-weight: 600;
  margin-bottom: 15px;
}

.cart .cartRt .cartSummary h5 {
  font-size: 18px;
  color: var(--primColor);
  font-weight: 600;
  margin: 0px;
  padding-left: 10px;
  display: inline;
}

.cart .cartRt .cartSummary > label {
  font-size: 15px;
  color: #595959;
  margin: 0px;
}

.cart .cartRt .cartSummary .primButton {
  margin-top: 20px;
}

.cart .emptyCart {
  width: 500px;
  margin: 20px auto;
}

.cart .emptyCart object {
  width: 160px;
}

.cart .emptyCart h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 15px;
}

.cart .emptyCart .primButton {
  width: max-content;
  margin: 0 auto;
}

.cart .cartItem .itemInfo {
  width: 70%;
}

.cart .cartItem .pclImg {
  max-width: 120px;
}

.cart .cartItem .listActBtn {
  width: 30%;
}

@media (max-width: 1024px) {
  .cart .cartCnt {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .cart .cartLt,
  .cart .cartRt {
    width: 100%;
  }
  .cart .cartRt {
    margin-bottom: 20px;
  }
  .cart .cartRt .cartSummary {
    display: flex;
    justify-content: space-between;
  }

  .cart .cartRt .cartSummary .primButton {
    margin-top: 0;
  }
  .cart .cartLt .cartHead h1 {
    font-size: 22px;
  }
}

@media (max-width: 500px) {
  .cart {
    padding-bottom: 0;
  }
  .cart .cartProducts {
    margin-bottom: 0;
  }
  .cart .cartLt .cartHead h1 {
    font-size: 20px;
  }

  .cartSummary {
    flex-wrap: wrap;
  }

  .cartSummary > div {
    width: 100%;
  }

  .cartSummary h5 {
    float: right;
  }

  .cartSummary .primButton {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .cart .cartLt .cartHead h1 {
    font-size: 18px;
  }
}
