.favoriteCheck {
  width: 40px;
  height: 45px;
  background: rgb(0, 0, 0, 20%);
  border-top-left-radius: 3px;
  position: relative;
}

.favoriteCheck input {
  display: none;
}

.favoriteCheck label {
  color: #fff;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  margin: 0;
  margin-top: 5px;
  user-select: none;
}

.favoriteCheck label:hover {
  color: grey;
}

.favoriteCheck label::selection {
  color: none;
  background: transparent;
}

.favoriteCheck label::moz-selection {
  color: none;
  background: transparent;
}

.favoriteCheck input:checked + label {
  color: var(--primColor);
}
