header {
  position: relative;
}

header .scrollTop {
  position: fixed;
  bottom: 3%;
  right: 3%;
  min-width: initial;
  background: #d8d8d8cc;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  z-index: 1;
  color: #2b2b2b;
  border: 1px solid #dbdbdb;
  display: none;
}

.topHeader {
  background: var(--secColor);
  height: 35px;
}
.topHeader ul,
.mainHeader .headRt ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.topHeader ul li:not(:last-child) {
  margin-right: 25px;
}

.topHeader ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}
.topHeader ul li a span {
  padding-right: 5px;
}

.mainHeader {
  padding: 10px 0;
}

.mainHeader .headLt img {
  max-width: 130px;
  width: 100%;
  height: auto;
}

.shopLocation span {
  color: var(--primColor);
  margin-right: 10px;
  display: inline-block;
}

.shopLocation p {
  margin: 0;
  color: var(--secColor);
}

.shopLocation p {
  margin: 0;
  color: var(--secColor);
}

.shopLocation p {
  margin: 0;
  color: var(--secColor);
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.shopLocation .headLocation {
  text-align: left;
}
.shopLocation .headLocation button .MuiButton-label {
  display: flex;
  align-items: center;
}

.shopLocation .headLocation .MuiButton-root {
  padding: 0;
  margin: 0;
  text-transform: none;
}

.shopLocation p:not(:first-child) {
  font-weight: 600;
}

.shopLocation p:last-child {
  font-weight: 600;
  color: var(--primColor);
}

.mainHeader .headRt ul li:not(:last-child) {
  margin-right: 25px;
}

.mainHeader .headRt ul li button.MuiButton-text .MuiButton-label {
  color: var(--accentColor);
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
}

.mainHeader .headRt ul li a {
  color: var(--accentColor);
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  -webkit-transition: 200ms color ease-in-out;
  -o-transition: 200ms color ease-in-out;
  transition: 200ms color ease-in-out;
}

.mainHeader .headRt ul li a:hover {
  color: #212121;
}

.mainHeader .headRt ul li span.material-icons {
  color: var(--accentColor);
}

.mainHeader .headRt ul li.headCart a {
  padding-left: 15px;
}

.bottomHeader {
  padding: 10px 0;
  border-bottom: 1px solid #e8e8e8;
}

.bottomHeader .MuiButtonBase-root.MuiListItem-root {
  color: var(--accentColor);
  font-size: 14px;
  font-weight: 600;
}

.bottomHeader .MuiListItem-root {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.respNav,
.respNavBtn {
  display: none !important;
}

.searchSlide {
  padding-top: 20px;
}

.searchSlide h3 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}

.searchSlide h3 span {
  margin-left: 10px;
  font-size: 15px;
  text-transform: capitalize;
  color: #808080;
  font-weight: 600;
}

.searchSlide .searchHeaderInput {
  height: 50px;
}

.searchSlide .searchHeaderInput input {
  height: inherit;
}

.searchSlide .searchHeaderInput input:focus {
  box-shadow: none;
  border-color: var(--primColor);
}

.searchSlide .searchHeaderInput .input-group-prepend {
  position: absolute;
  right: 1px;
  top: 1px;
  height: calc(100% - 2px);
  z-index: 100;
  background: #fff;
  color: var(--primColor);
  padding: 0;
  border: none;
}

.searchSlide .searchHeaderInput .input-group-prepend .MuiButton-label {
  height: 100%;
}

.searchSlide .searchHeaderInput .input-group-prepend .input-group-text {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  border: none;
  display: flex;
  justify-content: center;
  background: #fff;
  color: var(--primColor);
}

.popularCatg .catgType label h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px;
  width: 100%;
  color: #313131;
  text-align: center;
  margin-top: 10px;
  line-height: 22px;
}

.popularCatg .catgType .iconCnt {
  background: #efefef;
  padding: 10px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popularCatg .catgType label span {
  font-size: 32px;
  color: #848484;
}

.popularCatg .catgType label .MuiListItem-button {
  height: 100%;
  flex-wrap: wrap;
}

.searchSlide h4 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  color: #717171;
  margin-top: 30px;
}

.popularCatg .catgType {
  width: 33%;
  height: 90px;
  margin-bottom: 15px;
}

.popularCatg .catgType label {
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: 1px dashed #777;
}

.popularCatg .catgType input:checked ~ label {
  background: var(--primColor);
  color: #fff;
  border: 1px dashed var(--primColor);
}

.popularCatg .catgType input:checked ~ label h4 {
  color: #fff;
}

.popularCatg .catgType input:checked ~ label span {
  color: #ffc6bb;
}

.popularCatg .catgType input:checked ~ label .iconCnt {
  background: #ca2d0f;
}

.popularCatg .catgType label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 10px;
  -webkit-transition: 250ms all ease-in-out;
  -o-transition: 250ms all ease-in-out;
  transition: 250ms all ease-in-out;
}

.changeLocation .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}

.changeLocation h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.changeLocation .currLocation {
  font-size: 16px;
  font-weight: 700;
}

.changeLocation .otherLocation {
  margin-top: 15px;
}

.changeLocation .otherLocation .MuiListItem-root {
  font-size: 16px;
  padding-left: 0;
  padding-right: 0;
  justify-content: space-between;
}

.changeLocation .otherLocation .MuiListItem-root span.material-icons {
  color: var(--primColor);
}

.changeLocation .currLocation span.material-icons {
  padding-right: 5px;
  color: #bdbdbd;
}

/* RESPONSIVE */

@media (max-width: 1200px) {
  .bottomHeader .MuiListItem-root.MuiListItem-gutters {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  .bottomHeader .MuiListItem-root.MuiListItem-gutters {
    font-size: 12px;
  }

  .topHeader {
    display: none !important;
  }
  .shopLocation {
    position: absolute;
    width: 100%;
    background: #ececec;
    top: 0;
    left: 0;
    padding: 5px 40px;
    display: none;
  }

  .shopLocation .headLocation {
    display: flex;
    height: 25px;
    align-items: center;
  }

  .shopLocation p:not(:last-child) {
    padding-right: 10px;
    border-right: 1px solid #b1b1b1;
    margin-right: 10px;
  }

  .mainHeader .headLt img {
    max-width: 110px;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .deskNav {
    display: none !important;
  }
  .respNav {
    display: block !important;
  }
  .respNavBtn {
    display: inline-flex !important;
    min-width: max-content !important;
    margin-left: 10px !important;
    color: #000 !important;
  }
  .headRt.respNav .navRespLinks {
    padding-left: 0;
    list-style-type: none;
    flex-wrap: wrap;
  }

  .headRt.respNav .navRespLinks .MuiBadge-root {
    margin-right: 12px;
  }

  .headRt.respNav .navRespLinks a {
    width: 100%;
    text-decoration: none;
  }
  .headRt.respNav .MuiListItem-root {
    padding: 12px 20px;
    color: #000;
    justify-content: space-between;
  }
  .headRt.respNav .MuiListItem-root > div {
    display: flex;
    align-items: center;
  }

  .headRt.respNav .MuiListItem-root span.material-icons {
    padding-right: 10px;
    color: #adadad;
  }

  .headRt.respNav .naLogoHead {
    padding: 20px 0px 20px 30px;
  }
  .headerDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 300px;
  }

  .headerDrawer .mobShopLocation.MuiListItem-root {
    flex-wrap: wrap;
  }

  .headerDrawer .mobShopLocation.MuiListItem-root p {
    font-size: 14px;
    padding-left: 8px;
    margin: 0;
  }

  .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
    width: 100%;
    justify-content: space-between;
  }

  .headerDrawer .mobShopCurrLocation {
    padding-bottom: 0 !important;
  }

  .headerDrawer .headerDrawerNavLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: calc(100vh - 150px);
    height: auto;
  }

  .headerDrawer .headerDrawerNavLinks hr {
    width: 100%;
  }

  /* .headRt.respNav button {
        justify-content: space-between;
    } */

  .bottomHeader {
    display: none;
  }

  .navCategories {
    padding-left: 0;
    margin: 0;
    padding-top: 10px;
  }

  .navCategories .navBack {
    color: #8a8a8a;
  }

  .navCategories .MuiListItem-button {
    width: 100%;
  }
  .notLoggedLink {
    padding: 10px 20px;
    width: 100%;
  }
  .mainHeader {
    background: #fff;
    z-index: 100;
    box-shadow: 0px 3px 10px #cccccc61;
  }
  header {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 100;
  }
}

@media (max-width: 600px) {
  .shopLocation {
    padding: 5px 15px;
  }

  .shopLocation p {
    font-size: 12px;
  }

  .popularCatg .catgType {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .searchSlide h3,
  .searchSlide h4 {
    font-size: 16px;
  }
  .searchSlide h3 span {
    margin-left: 10px;
    font-size: 13px;
  }

  .popularCatg .catgType label h4 {
    font-size: 14px;
  }
}

/* ******* */
