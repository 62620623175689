.homeSponsoredBanner {
  width: 100%;
  height: 350px;
  margin-bottom: 80px;
}
.homeSponsoredBanner img {
  width: 100%;
  object-fit: cover;
  object-position: top;
}

@media (max-width: 767px) {
  .homeSponsoredBanner {
    height: auto;
    margin-bottom: 40px;
  }

  .homeSponsoredBanner img {
    height: 180px;
  }
}
