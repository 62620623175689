.staticPages {
  background: #fff;
  padding: 45px;
  text-align: left;
}
.staticPages h1 {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 700;
  color: #4e4e4e;
  margin-bottom: 45px;
  text-align: center;
}

.staticPages h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  color: #4e4e4e;
  margin: 45px auto 20px;
  text-align: left;
}

.staticPages p {
  font-size: 16px;
  line-height: 26px;
  color: #333;
}

.staticPages ul li {
  padding-bottom: 10px;
}
