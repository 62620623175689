.registration {
  background: var(--backgroundColor);
  padding: 75px 0;
}

.registration .regBox {
  background: #fff;
  width: 100%;
  height: 100%;
  max-width: 600px;
  padding: 45px 30px;
}

.registration h1 {
  font-size: 34px;
  color: var(--secColor);
  margin-bottom: 10px;
  text-align: center;
  font-weight: 700;
}

.registration h2 {
  font-size: 20px;
  font-weight: 400;
  color: var(--secColor);
}

.registration .regDetails {
  margin-top: 45px;
}

.registration .regDetails h5 {
  font-weight: 600;
  color: var(--secColor);
  font-size: 18px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 30px;
}

.registration .regTnC {
  margin-top: 45px;
}

.registration .regTnC .MuiFormControlLabel-root {
  margin: 0 auto;
}
.registration .regTnC p {
  font-size: 15px;
  color: #808080;
  margin-top: 10px;
  margin-bottom: 0;
}

.registration .regTnC a:link {
  color: var(--primColor) !important;
}

.registration .actBtn {
  margin-top: 45px;
}

.registration .actBtn button {
  width: 250px;
}

.registration .MuiStepIcon-root.MuiStepIcon-completed,
.registration .MuiStepIcon-root.MuiStepIcon-active {
  color: var(--primColor);
}

.registration .MuiStepper-root {
  padding: 20px 0 40px 0;
}

.registration .socialButtons {
  margin-top: 30px;
}

.registration .socialButtons button {
  height: 45px;
  width: 48%;
}

.registration .socialButtons .MuiButton-label {
  font-size: 13px;
  text-transform: initial;
}

.registration .socialButtons .MuiButton-label object {
  margin-right: 10px;
}

.registration .registrationDivider {
  text-transform: uppercase;
  margin: 35px 0;
  position: relative;
  font-size: 15px;
}

.registration .registrationDivider::before {
  position: absolute;
  content: '';
  height: 2px;
  width: 25%;
  top: 50%;
  left: 0;
  background: #ebebeb;
}

.registration .registrationDivider::after {
  position: absolute;
  content: '';
  height: 2px;
  width: 25%;
  top: 50%;
  right: 0;
  background: #ebebeb;
}

.registration .stepCompletion h3 {
  color: var(--primColor);
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 30px;
}

.registration .stepCompletion .primButton {
  width: 300px;
  margin: 20px auto;
}

.registration .stepCompletion object {
  width: 350px;
}

@media (max-width: 768px) {
  .registration {
    padding: 35px 0;
  }
}

@media (max-width: 500px) {
  .registration .regBox .socialButtons {
    flex-wrap: wrap;
  }
  .registration .regBox .socialButtons button {
    width: 100%;
  }

  .registration .regBox .socialButtons button:first-child {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .registration .regBox h1 {
    font-size: 28px;
  }
}

@media (max-width: 450px) {
  .registration .regBox .registrationDivider::before,
  .registration .regBox .registrationDivider::after {
    width: 15%;
  }
}

@media (max-width: 400px) {
  .registration .regBox {
    padding: 25px 15px;
  }
  .registration {
    background: #fff;
  }
}

@media (max-width: 380px) {
  .registration .regBox .registrationDivider::before,
  .registration .regBox .registrationDivider::after {
    width: 0%;
  }
}
