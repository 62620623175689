.invoiceCard {
  background: #fff;
  width: 250px;
  padding: 15px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.invoiceCard .icImg {
  background: #f5f5f5;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.invoiceCard .icImg img {
  max-width: 45px;
  width: 100%;
  height: auto;
}

.invoiceCard h3 {
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  margin: 0px 0 8px 0;
  color: var(--secColor);
}

.invoiceCard h5 {
  font-size: 15px;
  text-align: left;
  font-weight: 600;
  margin: 8px 0;
  color: var(--secColor);
}

.invoiceCard .icItems,
.invoiceCard .icPaid {
  margin: 8px 0;
}

.invoiceCard .icItems span,
.invoiceCard .icPaid span {
  font-size: 15px;
  font-weight: 600;
  color: var(--secColor);
}

.invoiceCard .icItems span:first-child,
.invoiceCard .icPaid span:first-child {
  font-weight: 400;
}

.invoiceCard .icPaid span:last-child {
  color: var(--primColor);
  font-weight: 700;
}

.invoiceCard .primButton {
  margin-top: 15px;
}

@media (max-width: 545px) {
  .invoiceCard {
    width: 100%;
  }
}
