.nellisInvoice {
  padding: 2rem !important;
  margin: 30px auto 30px;
  text-align: left;
  font-size: 14px;
}

.nellisInvoice .invLogo {
  width: 150px;
}

.nellisInvoice span.material-icons {
  padding-right: 5px;
}

.nellisInvoice .right {
  text-align: right !important;
}
.nellisInvTot span {
  font-weight: 500;
}

.nellisInvoice .invoiceViewPrint {
  width: 500;
  height: 500;
}

.nellisInvoice .invoiceView {
  margin-bottom: 30px;
  border: none;
  -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  border-top: 4px solid var(--primColor);
  background: #fff;
}

.nellisInvoice .card-header {
  background-color: #fff;
  border-bottom: 1px solid #e6e6f2;
}

.nellisInvoice .invoiceView h3 {
  font-size: 20px;
}

.nellisInvoice .invoiceTitle {
  font-size: 18px;
  margin: 0px 0 15px 0;
  font-weight: 600;
  color: #3a3a3a;
  text-align: center;
  text-transform: uppercase;
}

.nellisInvoice .invoiceSubTitle {
  font-size: 18px;
  margin: 35px 0 15px 0;
  font-weight: 600;
  color: #3a3a3a;
  text-align: left;
  text-transform: uppercase;
}

.nellisInvoice .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(241, 241, 241);
}
.nellisInvoice .invoiceView .table thead th {
  font-weight: 600;
  font-size: 15px;
}
.nellisInvoice .invoiceView .table tbody td {
  font-weight: 400;
  font-size: 15px;
}
.nellisInvoice .invoiceView h5 {
  font-size: 15px;
  line-height: 26px;
  color: #3d405c;
  margin: 0px 0px 15px 0px;
}

.nellisInvoice .text-dark {
  color: #3d405c !important;
}
.nellisInvoice .transDet .invDiv {
  /* width: 25%; */
  margin-bottom: 15px;
}

.nellisInvoice .transDet table th:last-child,
.nellisInvoice .transDet table td:last-child {
  text-align: right;
}

.nellisInvoice .transDet p {
  font-size: 15px;
  margin-bottom: 0px;
  color: #5f5f5f;
}
.nellisInvoice .transDet span {
  width: 100%;
  font-weight: 500;
}

.nellisInvoice .cartItem {
  padding: 0;
  margin-bottom: 15px;
}

.nellisInvoice .thankText {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  color: #7b7b7b;
  letter-spacing: 0.5px;
}

.nellisInvoice .transDet table {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */
.nellisInvoice .transDet table tr:nth-of-type(odd) {
  background: #fff;
  margin-bottom: 10px;
}
.nellisInvoice .transDet table th {
  background: #333;
  color: white;
  font-weight: bold;
}
.nellisInvoice .transDet table td,
th {
  padding: 6px;
  border: 1px solid #e6e6e6;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .nellisInvTot tbody td {
    width: 50%;
    display: inline-block;
  }
  .nellisInvoice .invoiceView .table tbody td {
    padding: 10px;
  }
  .nellisInvoice {
    margin: 15px auto 15px;
  }

  .nellisInvoice .transDet table {
    border: none;
  }
  .nellisInvoice .transDet table td,
  th {
    text-align: right;
  }

  /* Force table to not be like tables anymore */
  .nellisInvoice .transDet table,
  .nellisInvoice thead,
  .nellisInvoice tbody,
  .nellisInvoice th,
  .nellisInvoice td,
  .nellisInvoice tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .nellisInvoice .transDet thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  /* .nellisInvoice .transDet tr {
        border: 1px solid #ccc;
    } */

  .nellisInvoice .transDet td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  .nellisInvoice .transDet td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 9px;
    left: 10px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-title) ':';
    text-align: left;
    font-weight: 600;
    color: #000;
  }
}

@media (max-width: 500px) {
  .nellisInvoice .invoiceView h5 {
    margin-bottom: 5px !important;
  }

  .invDate {
    text-align: center;
    margin-top: 10px;
  }

  .card-body .invAddr {
    text-align: left !important;
  }

  .card-body .invAddr:last-child {
    margin-top: 20px;
  }
  .nellisInvoice .invoiceSubTitle {
    font-size: 16px;
    margin-top: 25px !important;
  }
  .nellisInvoice .transDet td:before {
    font-size: 14px;
  }
  .cartItem .listContent .listProdTitle {
    font-size: 15px;
  }
  .nellisInvoice {
    padding: 15px !important;
  }
  .card-header {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .card-header a {
    margin: 0 auto;
  }

  .card-body > div {
    /* width: 100%; */
    float: none;
    text-align: center;
    margin-top: 10px;
  }
}
