.filterPanel .fpTitle {
  color: var(--primColor);
}

/*.filterPanel .MuiPaper-root.MuiAccordion-root {*/
/*    border: none;*/
/*    margin-bottom: 5px;*/
/*}*/

.filterPanel .accTitle {
  color: var(--primColor);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}

.filterTitleCnt {
  margin-bottom: 10px;
}

.filterPanel h4 {
  margin-bottom: 0;
}

.filterPanel .filterClear {
  text-transform: none;
}

.filterPanel .MuiAccordionDetails-root {
  padding: 10px 16px;
}

.filterPanel .filterCheck .customCheckbox,
.filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row {
  width: 100%;
}

.filterPanel .filterCheck .MuiFormControlLabel-root,
.filterPanel .filterCheck .MuiTypography-root.MuiFormControlLabel-label,
.filterPanel .filterCheck .MuiListItem-button {
  /*width: 100%;*/
  margin: 0;
}

.filterPanel .filterCheck .MuiListItem-button {
  padding: 0;
  font-size: 15px;
}

.RadioBox .MuiListItem-button {
  padding: 0;
}

.RadioBox .MuiAccordionDetails-root {
  padding-top: 0;
}

.filterPanelSkeleton {
  /* height: 60vh; */
  margin-bottom: 30px;
}

.filterPanel .filterCheck {
  width: 100%;
}

.filterPanel .priceSlider {
  width: 100%;
}

.filterPanel .priceSlider h5 {
  font-size: 15px;
  text-align: left;
  color: #2b2b2b;
  font-weight: 600;
}

.filterPanel .priceSlider h5 span {
  padding: 0 5px;
}

/* For Material UI input */
.MuiPaper-root .MuiInputBase-root input {
  margin-left: 5px;
}

/* For Selected Filters component */
.filterPanel .MuiButton-outlined .MuiButton-label {
  color: var(--secColor);
}

/* For Suggested Retail Price Filter */
.MuiFormGroup-root {
  width: 100%;
}
