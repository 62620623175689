:root {
  --fontFamily: 'Open Sans';
  --primColor: rgb(174, 34, 7);
  --secColor: rgb(43, 43, 43);
  --accentColor: rgb(151, 151, 151);
  --backgroundColor: #f5f5f5;
  --starColor: #fdcc0d;
}

.primButton button {
  color: #fff;
  border-radius: 3px;
  background-color: var(--primColor);
  border: 1px solid var(--primColor);
  transition: 200ms all ease-in-out;
  box-shadow: none;
}

.primButton button:hover {
  background-color: var(--primColor);
  border: 1px solid var(--primColor);
  color: #fff;
  opacity: 0.9;
  box-shadow: none;
}

.secButton button {
  color: var(--primColor);
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid var(--primColor);
  transition: 200ms all ease-in-out;
  box-shadow: none;
}

.secButton button:hover {
  background-color: var(--primColor);
  border: 1px solid var(--primColor);
  color: #fff;
  box-shadow: none;
}

.primButton button,
.secButton button {
  height: 50px;
}

.primButton.small button,
.secButton.small button {
  height: 40px;
  min-width: max-content;
}

.primButton.large button,
.secButton.large button {
  height: 60px;
}

::-moz-selection {
  /* Code for Firefox */
  color: #4e4e4e;
  background: #ffddd6;
}

::selection {
  color: #4e4e4e;
  background: #ffddd6;
}

@media (max-width: 500px) {
  .primButton button,
  .secButton button,
  .primButton.small button,
  .secButton.small button {
    height: 45px;
    font-size: 13px;
  }
}
