.home {
  background: var(--backgroundColor);
  padding-top: 30px;
  padding-bottom: 30px;
}

.home .sectionTitle {
  margin-bottom: 10px;
}

.home .sectionTitle > a {
  font-size: 14px;
  color: var(--primColor);
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 12px;
}

.home .productNav {
  padding: 0 25px;
}

.trendingItems .slick-arrow.slick-next {
  position: absolute;
  top: -5%;
  right: 0%;
  display: none;
}

.trendingItems .slick-arrow.slick-prev {
  position: absolute;
  top: -5%;
  left: auto;
  right: 8%;
  display: none;
}

.trendingItems .slick-track {
  align-self: stretch;
}

.trendingItems .slick-arrow.slick-next:before {
  color: var(--primColor);
  content: '\f0da';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.trendingItems .slick-arrow.slick-prev:before {
  color: var(--primColor);
  content: '\f0d9';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.trendingItems .productCardGrid {
  width: auto;
  margin-left: 10px;
}

.trendingItems .slick-slide {
  /* margin-left: 10px; */
}

.trendingItems .slick-slide > div {
  width: inherit;
}

.toggleRespDrawer.MuiButtonBase-root {
  display: none;
}

@media (max-width: 1200px) {
  .trendingItems .slick-arrow.slick-prev {
    right: 12%;
  }
}

@media (max-width: 900px) {
  .trendingItems .slick-arrow.slick-prev {
    right: 9%;
  }
}

@media (max-width: 767px) {
  /* .home {
        padding-top: 10%;
    }
    .home .homeLt {
        position: absolute;
        left: 0;
        top: 110px;
        padding: 15px 40px;
        background: #fff;
    }

    .home .hmSearch {
        margin: 0;
    } */
  .trendingItems .slick-arrow.slick-prev {
    right: 11%;
  }
}

@media (max-width: 500px) {
  .trendingItems .slick-slide {
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .trendingItems .slick-arrow.slick-prev,
  .trendingItems .slick-arrow.slick-next {
    display: none;
  }
}
