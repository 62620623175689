.search {
  background: var(--backgroundColor);
  padding-top: 30px;
  padding-bottom: 30px;
}
.search .sectionTitle .searchInfo h6 {
  font-size: 14px;
  color: 14px;
  font-weight: 600;
  color: #999999;
}

.search .sectionTitle .searchInfo h6:first-child {
  color: var(--primColor);
  margin-right: 30px;
}

.search .searchResults .productCardGrid {
  margin-right: 5px;
}

.search .searchLt .customSelect {
  background: #fff;
  margin-top: 20px;
  margin-bottom: 0;
}

.search .searchLt .gridListToggle button {
  color: var(--secColor);
  width: 50%;
}

.search .searchLt .gridListToggle button.active {
  color: var(--primColor);
  background: #fff;
}

.search .searchLt .gridListToggle i {
  padding-right: 10px;
}

.search .searchInput input::-webkit-input-placeholder {
  color: #b7b7b7;
}
.search .searchInput input:focus::-webkit-input-placeholder {
  color: #464646;
}

/* Firefox < 19 */
.search .searchInput input:-moz-placeholder {
  color: #b7b7b7;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: #464646;
}

/* Firefox > 19 */
.search .searchInput input::-moz-placeholder {
  color: #b7b7b7;
}
.search .searchInput input:focus::-moz-placeholder {
  color: #464646;
}

/* Internet Explorer 10 */
.search .searchInput input:-ms-input-placeholder {
  color: #b7b7b7;
}
.search .searchInput input:focus:-ms-input-placeholder {
  color: #464646;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
  width: 50vmax;
  max-width: 800px;
  padding: 20px;
}

.searchSkeletonBody {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.searchSkeletonBody .skeletonWrapper {
  background: #fff;
}

@media (max-width: 1024px) {
  .home .hmSearch .searchInput,
  .search .searchInput {
    width: 100%;
    margin-bottom: 0 !important;
  }

  .searchLt {
    display: none;
  }

  .sbTitle {
    font-size: 14px;
    color: var(--secColor);
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .searchAuctions {
    margin-bottom: 20px;
  }

  .searchAuctions label,
  .searchAuctions button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    color: var(--primColor);
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #d8d8d8;
    border-radius: 25px;
    margin-right: 10px;
    text-decoration: none;
    transition: 200ms all ease-in-out;
    font-size: 16px;
    font-weight: 600;
    text-transform: initial;
    padding: 5px 15px;
    margin-bottom: 10px;
    height: 40px;
  }

  .searchAuctions button.active {
    background: rgb(249 238 236);
    color: var(--primColor);
    border: 1px solid rgb(174 34 7 / 51%);
  }

  .searchAuctions button .MuiButton-label {
    font-size: 13px;
  }

  .searchAuctions input[type='radio']:checked + label {
    background: rgb(249 238 236);
    color: var(--primColor);
    border: 1px solid rgb(174 34 7 / 51%);
  }
}

@media (max-width: 600px) {
  .searchAuctions label {
    height: 40px;
    font-size: 13px;
  }

  .rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 90%;
  }
  .productView .pvBidBox .customInput,
  .productView .pvBidBox .customInput .MuiFormControl-root.MuiTextField-root {
    height: 45px;
  }
  .productView .pvBidBox .customInput input {
    height: 100%;
  }
  .productView .pvActBtn {
    flex-wrap: wrap;
  }
  .productView .pvActBtn .pabChild:first-child {
    width: 100%;
  }
  .search .sectionTitle .searchInfo h6:first-child {
    display: none;
  }
}

@media (max-width: 500px) {
  .search .sectionTitle .searchInfo h6 {
    font-size: 12px;
  }
}
